.App {
  background-color: 	#038cab;
  font-size: calc(10px + 2vmin);
  color: white;
  min-height: 100vh;
  padding-bottom: 100px;
}

.App-header {
  background-color: 	#038cab;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

ul {
  line-height: 1.6;
  /* list-style-type: none; */
  margin: 0;
  padding-left: 20px;
}

.dark-blue {
  color: #012946;
}

.logo-row {
  background-color: white;
  height: 10vh;
  padding: 1vh;
}
.logo-img {
  max-height: 50px;
}
.book-img {
  height: 60vh;
}

.main-content {
  padding: 5vh;
}

.buy-book-col {
  margin: 2vh 0;
}
